import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import FacebookCircleSvgIcon from '../../images/icons/fb-circle-logo.svg';
import { linkUrl } from '../../utils/LinkUrl';
import { theme } from '../../utils/Theme';
import { CircleImage } from '../CircleImage';

interface KindergartenLinkProps {
  image: FluidObject;
  facebookLink: string | undefined;
  kindergartenName: string;
  color: string;
}
const Anchor = styled(Link)`
  color: ${({ color }) => color};
  font-family: 'Recoleta-Medium';
  text-decoration: none;
  font-size: 3.2vw;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  transition: all 0.4s ease-out;
  width: 420px;
  ${theme.mediaQuerySizes.bigScreen} {
    width: 80%;
  }
  ${theme.mediaQuerySizes.laptop} {
    margin-bottom: 2.5rem;
  }
  ${theme.mediaQuerySizes.smallLaptop} {
    margin-bottom: 1rem;
  }
  ${theme.mediaQuerySizes.phone} {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
  ${theme.mediaQuerySizes.smallScreen} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  :hover div {
    border: 0px solid ${({ color }) => color};
    transform: rotate(5deg);
  }
  ${theme.mediaQuerySizes.phone} {
    font-size: 8vw;
  }
  ${theme.mediaQuerySizes.smallScreen} {
    font-size: 10vw;
  }
`;

const FacebookIcon = styled(FacebookCircleSvgIcon)`
  width: 60px;
  height: auto;
  transform: rotate(0deg);
  transition: all 0.4s ease-out;
  :hover {
    transform: rotate(5deg);
  }
`;
const FacebookLink = styled('a')`
  position: absolute;
  bottom: 20%;
  right: 18%;
  ${theme.mediaQuerySizes.bigScreen} {
    right: 15%;
  }
  ${theme.mediaQuerySizes.bigLaptop} {
    bottom: 23%;
  }
  ${theme.mediaQuerySizes.smallLaptop} {
    bottom: 17%;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    bottom: 23%;
    right: 26%;
  }
  ${theme.mediaQuerySizes.smallScreen} {
    bottom: 18%;
  }
`;

const Kindergarten = styled('div')`
  display: flex;
  justify-content: center;
  position: relative;
`;

export class KindergartenLink extends PureComponent<KindergartenLinkProps> {
  public render(): JSX.Element {
    const kindergartenNameKebabCase = this.props.kindergartenName.replace(/\s+/g, '-').toLowerCase();
    return (
      <Kindergarten>
        <Anchor color={this.props.color} to={linkUrl(kindergartenNameKebabCase)}>
          <CircleImage borderColor={this.props.color} borderSize={15} image={this.props.image}></CircleImage>
          {this.props.kindergartenName}
        </Anchor>
        <FacebookLink href={this.props.facebookLink} aria-label="Facebook">
          <FacebookIcon></FacebookIcon>
        </FacebookLink>
      </Kindergarten>
    );
  }
}
