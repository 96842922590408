import 'react-toastify/dist/ReactToastify.css';

import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Logo } from '../components/Logo';
import { KindergartenLink } from '../components/main-page/KindergartenLink';
import { SEO } from '../components/Seo';
import StripesBottomColor from '../components/StripesBottomColor';
import { FluidImage } from '../interfaces';
import { MainPageLayout } from '../layouts/MainPageLayout';
import { findKindergartenByAllKindergertensJson } from '../utils/FindKindergartenByAllKindergartensJson';
import { findSocialUrl } from '../utils/SocialUrl';
import { theme } from '../utils/Theme';

const Container = styled.div`
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: auto;
  margin: 0 auto;
  margin-bottom: 1.45rem;

  ${theme.mediaQuerySizes.phone} {
    grid-template-columns: 1fr;
  }
`;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 420, maxHeight: 420, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    si: file(relativePath: { eq: "home-slogan/1.jpg" }) {
      ...fluidImage
    }
    ni: file(relativePath: { eq: "home-slogan/2.jpg" }) {
      ...fluidImage
    }
    pno: file(relativePath: { eq: "home-slogan/3.jpg" }) {
      ...fluidImage
    }
    allKindergartensJson: allKindergartensJson {
      nodes {
        name
        color
        shortcut
      }
    }
    kindergartensOrder: markdownRemark(fileAbsolutePath: { regex: "/_data/settings/kindergartens-order.md/" }) {
      frontmatter {
        collection {
          collectionName
        }
      }
    }
  }
`;

interface Kindergarten {
  name: string;
  facebook: string;
  instagram: string;
  youtube: string;
  color: string;
  shortcut: 'si' | 'ni' | 'pno';
}

interface queryResponse {
  data: {
    si: FluidImage;
    ni: FluidImage;
    pno: FluidImage;
    allKindergartensJson: {
      nodes: Kindergarten[];
    };
    kindergartensOrder: {
      frontmatter: {
        collection: { collectionName: string }[];
      };
    };
  };
}

let kindergarten;

const IndexPage = ({ data }: queryResponse) => (
  <>
    <MainPageLayout>
      <SEO title="Strona główna" />
      <Logo />
      <Container>
        {data.kindergartensOrder.frontmatter.collection.map((collection) => {
          kindergarten = findKindergartenByAllKindergertensJson(data, collection.collectionName) as Kindergarten;
          return (
            <KindergartenLink
              key={kindergarten?.name}
              color={theme.colors[kindergarten?.color]}
              image={data[kindergarten?.shortcut].childImageSharp.fluid}
              kindergartenName={kindergarten?.name}
              facebookLink={findSocialUrl(kindergarten?.name, 'facebook')}
            ></KindergartenLink>
          );
        })}
      </Container>
    </MainPageLayout>
    <StripesBottomColor />
  </>
);

export default IndexPage;
