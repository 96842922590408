import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { theme } from '../utils/Theme';

interface CircleImageProps {
  image: FluidObject | FluidObject[] | undefined;
  borderColor: string;
  borderSize?: number;
}

interface ImageProps {
  borderColor: string;
  borderSize?: number;
}

const rotate = keyframes`
  from {
    opacity: 0;
    transform: rotate(-10deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
`;

const StyledCircleImage = styled(Img)<ImageProps>`
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 50%;
  border: ${({ borderSize }) => (borderSize ? borderSize : 15)}px solid ${({ borderColor }) => borderColor};
  transform: rotate(0deg);
  margin-bottom: 2rem;
  transition: all 0.4s ease-out;
  animation: ${rotate} 1s;
  :hover {
    border-width: 0px;
    transform: rotate(5deg);
  }
  ${theme.mediaQuerySizes.smallLaptop} {
    border: ${({ borderSize }) => (borderSize ? (borderSize / 4) * 3 : 7)}px solid ${({ borderColor }) => borderColor};
  }

  ${theme.mediaQuerySizes.phoneLight} {
    border: ${({ borderSize }) => (borderSize ? (borderSize / 4) * 2 : 7)}px solid ${({ borderColor }) => borderColor};
    width: 70%;
  }
`;

export const CircleImage = (props: CircleImageProps) => {
  return <StyledCircleImage borderColor={props.borderColor} fluid={props.image || []} borderSize={props.borderSize} />;
};
