import React from 'react';
import styled from 'styled-components';

import FullLogo from '../images/full-logo.svg';
import { theme } from '../utils/Theme';

const LogoSection = styled.div`
  font-family: Recoleta-SemiBold;
  font-weight: 900;
  color: ${theme.colors.logoGreen};
  width: 420px;
  margin: 0 auto;
  ${theme.mediaQuerySizes.tablet} {
    width: 55%;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    width: 70%;
  }
  ${theme.mediaQuerySizes.smallScreen} {
    width: 60%;
  }
  p {
    font-size: 3.2rem;
    margin-top: -35px;
    margin-bottom: 15px;
    ${theme.mediaQuerySizes.laptop} {
      font-size: 2.3rem;
      margin-top: 0px;
    }
    ${theme.mediaQuerySizes.tablet} {
      font-size: 5.8vw;
      margin-top: 7px;
    }
    ${theme.mediaQuerySizes.phone} {
      margin-bottom: 0;
      margin-top: 0;
    }
    ${theme.mediaQuerySizes.phoneLight} {
      font-size: 7vw;
    }
    ${theme.mediaQuerySizes.smallScreen} {
      font-size: 6.2vw;
      margin-top: -8px;
    }
  }
`;

const Empty = styled.div`
  margin-left: 10%;
  ${theme.mediaQuerySizes.laptop} {
    margin-left: 21%;
  }
  ${theme.mediaQuerySizes.tablet} {
    margin-left: 19%;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    margin-left: 21%;
  }
`;

const NutkaLogo = styled(FullLogo)`
  width: 450px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  ${theme.mediaQuerySizes.laptop} {
    width: 100%;
    margin-bottom: 0;
  }
  ${theme.mediaQuerySizes.phone} {
    margin-bottom: 0;
  }
`;

export const Logo = () => {
  return (
    <LogoSection>
      <Empty>
        <NutkaLogo />
        <p>przedszkole</p>
      </Empty>
    </LogoSection>
  );
};
