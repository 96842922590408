import React from 'react';
import styled from 'styled-components';

import { theme } from '../utils/Theme';

const Stripes = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: red;
  height: 10px;
  margin-top: -10px;
`;

const StripeColor = styled.div`
  background-color: ${({ color }) => color};
  width: inherit;
`;

const StripesBottomColor = () => (
  <Stripes>
    <StripeColor color={theme.colors.violet}></StripeColor>
    <StripeColor color={theme.colors.pink}></StripeColor>
    <StripeColor color={theme.colors.blue}></StripeColor>
  </Stripes>
);

export default StripesBottomColor;
